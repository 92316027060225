// ===[ Variables ]=== //
$dark-color: #454d5d !default;
$bg-color: lighten($dark-color, 66%) !default;
$bg-color-dark: darken($bg-color, 3%) !default;

// --- Core colors
$primary-color: #2f80ed !default;
$body-bg: $bg-color-dark !default;
$body-font-color: lighten($dark-color, 5%) !default;
$link-color: $primary-color !default;
$link-color-dark: darken($link-color, 10%) !default;
$link-color-light: lighten($link-color, 10%) !default;

// --- Fonts
// Credit: https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/
$base-font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  Roboto !default;
$mono-font-family: 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', Menlo, Courier,
  monospace !default;
$fallback-font-family: 'Helvetica Neue', sans-serif !default;
$body-font-family: $base-font-family, $fallback-font-family !default;

// --- Font sizes
$html-font-size: 20px !default;
$html-line-height: 1.5 !default;
$font-size: 0.7rem !default;

// ===[ Base ]=== //
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: $html-font-size;
  line-height: $html-line-height;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
}

body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: $font-size;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  height: 100%;
}

#root {
  height: 100%;
}

a {
  color: $link-color;
  outline: none;
  text-decoration: none;

  &:focus,
  &:hover,
  &:active,
  &.active {
    color: $link-color-dark;
    text-decoration: underline;
  }

  &:visited {
    color: $link-color-light;
  }
}
