@import '~bootstrap-utilities';

.ant-card-status {
  position: absolute;
  top: 6px;
  right: 8px;
  button {
    background: transparent;
    font-size: 10px;
    height: 14px;
    color: #1890ff;
    border-radius: 7px;
    padding: 0 8px;
    display: block;
    font-weight: 500;
    line-height: 1;
    box-shadow: none;
  }
}
.ant-card-meta {
  margin-bottom: 4px;
}
.ant-card-body {
  position: relative;
}
.ant-card-info {
  font-size: 12px;
  font-weight: 500;
}
.ant-calendar-picker-container,
.ant-dropdown {
  z-index: 3000 !important;
}
// .ant-legacy-form-item-label {
//   white-space: inherit !important;
//   line-height: 1.5 !important;
// }

// ---------------- BEGIN CKEditor ----------------
.rtf-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;

  /* Adjust the look of the toolbar inside of the container. */
  .ck-toolbar {
    z-index: 1;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--ck-color-toolbar-border);
  }
}

/* Set the default font for the "page" of the content. */
.rtf-editor .ck-content,
.rtf-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.rtf-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.rtf-editor
  .ck-heading-dropdown
  .ck-list
  .ck-heading_heading1
  .ck-button__label,
.rtf-editor
  .ck-heading-dropdown
  .ck-list
  .ck-heading_heading2
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.rtf-editor .ck-content h2,
.rtf-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.rtf-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.rtf-editor .ck-content h3,
.rtf-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.rtf-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.rtf-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.rtf-editor .ck-content h4,
.rtf-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.rtf-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Make the block quoted text serif with some additional spacing. */
.rtf-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
// ---------------- END CKEditor ----------------

.ant-calendar-picker {
  display: block;
}

.ant-select {
  .ant-select-selection__choice {
    border-radius: 11px;
    font-size: 12px;
    margin-top: 8px !important;
    height: 24px !important;
    line-height: 22px !important;
  }
  &.ant-select-primary {
    .ant-select-selection__choice {
      background-color: #1890ff;
      border-color: #1890ff;
      color: #fff;
      .ant-select-selection__choice__remove {
        color: #fff;
      }
    }
  }
  &.ant-select-danger {
    .ant-select-selection__choice {
      background-color: #e50000;
      color: #fff;
    }
    &.ant-select-outline {
      .ant-select-selection__choice {
        color: #222;
        background-color: rgba(229, 0, 0, 0.05);
        border: 1px solid #e50000;
        .ant-select-selection__choice__remove {
          color: #222;
        }
      }
    }
  }
}

.width-method-type-option {
  display: none !important;
}

//css fix for form item with long labels
.two-rows-label .ant-legacy-form-item-label {
  label {
    white-space: normal;
    margin-right: 12px;
    display: inline-block;
    text-align: right;
    line-height: 20px;
    width: 200px;
    &:after {
      content: none;
    }
    &.ant-legacy-form-item-required:before {
      position: absolute;
      left: -7px;
    }
  }
}
