.header-icons-container {
  padding-left: 10px;
  min-width: 53px;
  width: 13%;
  line-height: 8px;
}

.member-profile-tab-container {
  min-height: 233px;
}
