// variables, reset, and dependencies
@import 'normalize';
@import 'base';

// ----------------------------------------------------------
// Put here the class that will be used allthroughout the app

:root {
  --nav-bg-color: #1b48a0;
  --link-text-color: #1890ff;
}

body {
  font-family: 'Helvetica Neue', -apple-system, system-ui, BlinkMacSystemFont,
    'Segoe UI', Roboto, sans-serif !important;
}

// --- Left over legacy Spectre CSS in use --- //
.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}

.d-inline-block {
  display: inline-block;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.flex-g-1 {
  flex-grow: 1;
}
// --- End Spectre CSS Related --- //

.custom-date-range-time-picker {
  .ant-calendar-time-picker {
    .ant-calendar-time-picker-inner {
      padding-top: 0 !important;
    }
  }
  .ant-calendar-time-picker-btn {
    display: none !important;
  }
}

.text-black {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-legacy-form-item {
  margin-bottom: 0.4rem !important;
}

/* topbar-menu specific styles */
.topbar-menu {
  .ant-dropdown-menu {
    padding: 0;
    border-bottom: none;
    background-color: transparent;
    align-items: center;
    line-height: 40px;
    border-radius: 0;
    background-color: var(--nav-bg-color);
  }
  .ant-dropdown-menu-item {
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 40px;
    padding: 0 10px;
    &:hover {
      background-color: #1890ff !important;
    }
  }
  .ant-dropdown-menu-item {
    border-bottom: none !important;
  }
}
/** -------------------------- */

.ant-layout-header {
  padding: 0 16px !important;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  a {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
    &:hover {
      background-color: #fff !important;
    }
  }
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.truncate {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styled-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@for $i from 8 through 24 {
  .fs-#{$i} {
    font-size: $i * 1px !important;
  }
}

@for $i from 1 through 7 {
  .fw-#{$i * 100} {
    font-weight: $i * 100 !important;
  }
}

.text-black {
  &--25 {
    color: rgba(0, 0, 0, 0.25);
  }

  &--45 {
    color: rgba(0, 0, 0, 0.45);
  }

  &--65 {
    color: rgba(0, 0, 0, 0.65);
  }

  &--85 {
    color: rgba(0, 0, 0, 0.85);
  }
}
.text-muted-light {
  color: rgba(0, 0, 0, 0.45) !important;
}

// style hack! remove the timepicker excess whitespace on the bottom COVID-844
.ant-time-picker-panel-select ul {
  padding: 0 0 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.display-none {
  display: none !important;
}

.flex-direction-column {
  flex-direction: column;
}

.ant-tooltip-inner {
  max-width: none !important;
}

.ant-message {
  z-index: 10000 !important;
}

.deposit-withdrawal-tab-member360 .ant-tabs-nav-wrap {
  display: block !important;
}

.promo-games-tab-member360 .ant-tabs-nav-wrap {
  display: block !important;
}
